
import {PropType, defineComponent} from 'vue';
import i18n from '@/vendor/I18n';

export interface TableHeader {
    title: string;
    col?: string;
}

/**
 * Creates a table component.
 * Will generate headers which can all individually be overridden by using the
 * slots 'header-<headerName>'.
 * Will generate rows and autofill the cells by accessing 'row[<headerName>]'.
 * The generated cells can be overridden by using the slots '<headerName>'.
 * If there are no rows, the table will display a row with the 'no results'
 * text which can be overridden with the 'no-results' slot.
 * Optional 'rows-append' row is available to append rows to the bottom of the
 * table.
 * Emits 'row-clicked' whenever a user clicks on any row or a cell therein.
 */
export default defineComponent({
    props: {
        headers: {
            type: Array as PropType<string[]|TableHeader[]>,
            required: true,
        },
        noResultsText: {
            type: String,
            default: i18n.global.t('words.noResults'),
        },
        preventRowClick: {
            type: Boolean,
            default: false,
        },
        rowKey: {
            type: String,
            default: null,
        },
        rows: {
            type: Array as PropType<Record<string, any>[]>,
            required: true,
        },
        spacedRows: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'row-clicked',
    ],
    computed: {
        tableClasses(): string[] {
            return [this.spacedRows ? 'spaced-rows spaced-hover' : 'table-hover'];
        },
        tableHeaders(): TableHeader[] {
            /*
             * Check if the headers property is an array of strings or table
             * header objects.
             */
            if (typeof this.headers[0] === 'string') {
                return (this.headers as string[]).map((header: string) => ({
                    title: header,
                    col: 'col',
                }));
            }

            return (this.headers as TableHeader[]).map((header: TableHeader) => {
                return header.col
                    ? header
                    : {
                        ...header,
                        col: 'col',
                    };
            });
        },
    },
    methods: {
        rowClick(row: Record<string, any>) {
            if (!this.preventRowClick) {
                this.$emit('row-clicked', row);
            }
        },
    },
});
