import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["data-placement", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("span", {
    "data-placement": _ctx.tooltipPlacement,
    title: _ctx.noTooltip ? '' : _ctx.formattedDate
  }, [
    _createTextVNode(_toDisplayString(_ctx.humanReadableTime), 1)
  ], 8, _hoisted_1)), [
    [_directive_tooltip]
  ])
}