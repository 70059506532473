import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: "row-no-results",
  class: "pointer-events-none"
}
const _hoisted_3 = {
  class: "text-center",
  colspan: "999"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", {
    class: _normalizeClass(["table", _ctx.tableClasses])
  }, [
    (_ctx.tableHeaders.length)
      ? (_openBlock(), _createElementBlock("thead", _hoisted_1, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (header) => {
              return _renderSlot(_ctx.$slots, `header-${header.title}`, { header: header }, () => [
                (_openBlock(), _createElementBlock("th", {
                  key: header.title,
                  class: _normalizeClass(["whitespace-nowrap text-muted", [header.col, _ctx.spacedRows ? 'pt-4 pb-0' : 'py-4']])
                }, _toDisplayString(header.title !== 'options' ? _ctx.$t(`headers.${header.title}`) : ''), 3))
              ])
            }), 256))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("tbody", null, [
      (!_ctx.rows.length)
        ? _renderSlot(_ctx.$slots, "no-results", { key: 0 }, () => [
            _createElementVNode("tr", _hoisted_2, [
              _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.noResultsText), 1)
            ])
          ])
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.rows, (row, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: row[_ctx.rowKey] || `row-${index}`,
              class: _normalizeClass({'cursor-pointer': !_ctx.preventRowClick}),
              onClick: ($event: any) => (_ctx.rowClick(row))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (property) => {
                return _renderSlot(_ctx.$slots, property.title, { row: row }, () => [
                  (_openBlock(), _createElementBlock("td", {
                    key: (row[_ctx.rowKey] || `row-${index}`) + `-${property.title}`
                  }, _toDisplayString(row[property.title]), 1))
                ])
              }), 256))
            ], 10, _hoisted_4))
          }), 128)),
      _renderSlot(_ctx.$slots, "rows-append")
    ])
  ], 2))
}