
import {PropType, defineComponent} from 'vue';
import {fetchCollectionUntilScrollable, firstScrollingParent} from '@/library/helpers';
import Collection from '@/models/Collection';
import OwTable from '@/components/common/OwTable.vue';
import i18n from '@/vendor/I18n';

/**
 * Uses the Table component.
 * Links the passed collection to the table rows.
 * By default it will fetch the collection until the table is scrollable on
 * mounted.
 * Implements infinite scroll for fetching the next page of the collection.
 * If infinite scroll is disabled, it will show a button to manually load more.
 * Forwards all the slots from the Table component.
 */
export default defineComponent({
    components: {
        OwTable,
    },
    inheritAttrs: false,
    props: {
        collection: {
            type: Object as PropType<Collection<Record<string, any>, Record<string, any>>>,
            required: true,
        },
        disableInfiniteScroll: {
            type: Boolean,
            default: false,
        },
        loadMoreTitle: {
            type: String,
            default: i18n.global.t('actions.clickToLoadMore'),
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        showLoadMore(): boolean {
            return this.disableInfiniteScroll
                && !this.collection.lastPageFetched();
        },
        rows(): Record<string, any>[] {
            return this.collection.all();
        },
    },
    watch: {
        'collection.filters': {
            handler(): void {
                if (!this.disableInfiniteScroll) {
                    this.fetchInfiniteScrollCollection();
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (
            this.collection.isEmpty()
            && !this.disableInfiniteScroll
        ) {
            this.fetchInfiniteScrollCollection();
        }
    },
    methods: {
        fetchInfiniteScrollCollection() {
            this.collection.clear();

            fetchCollectionUntilScrollable(
                this.collection,
                firstScrollingParent(this.$el),
                this.options,
            );
        },
        loadMore() {
            if (!this.collection.lastPageFetched()) {
                this.collection.fetch(this.options);
            }
        },
    },
});
