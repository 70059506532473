
import {defineComponent} from 'vue';
import format from 'date-fns/format';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import parseISO from 'date-fns/parseISO';

/**
 * Parses a date to a time ago text.
 * Adds a tooltip on hover to display the full date that was passed.
 */
export default defineComponent({
    props: {
        date: {
            type: String,
            required: true,
        },
        dateStructure: {
            type: String,
            default: 'dd/MM/yyyy',
        },
        noTooltip: {
            type: Boolean,
            default: false,
        },
        tooltipPlacement: {
            type: String,
            default: 'top',
            validator: (value: string) => ['auto', 'top', 'bottom', 'left', 'right'].includes(value),
        },
    },
    computed: {
        humanReadableTime() {
             return formatDistanceToNowStrict(parseISO(this.date), {
                 addSuffix: true,
             });
        },
        formattedDate() {
            return format(parseISO(this.date), this.dateStructure);
        },
    },
});
